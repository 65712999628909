/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    p: "p",
    h4: "h4",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Minimum Viable Documentation"), "\n", React.createElement(_components.p, null, "Minimal Viable Documentation must be complete and accurate, with no broken links.\nAny provided code samples must be functional.\nTo audit your first round of documentation, review each page with the following questions in mind:"), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is the content accurate?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is the content complete?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do all links work?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Does all link text accurately describe the referral page?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do all code samples work?")), "\n", React.createElement(_components.p, null, "Once you have completed the minimum viable documentation,\nyou can incrementally improve developer experience."), "\n", React.createElement(_components.h2, null, "Developer Experience"), "\n", React.createElement(_components.p, null, "Use the questions below to evaluate the experience of developers using your documentation."), "\n", React.createElement(_components.h4, null, "General"), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do the title and introduction clearly convey the goal of the guide?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Can a user check their progress regularly?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do guides contain specific next steps?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do headers, titles and list items use consistent parts of speech, where possible?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are guides of a similar length, when appropriate?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do any guides need to be combined?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do any guides need to be separated?")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do guides follow a generally consistent format?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is syntax consistent?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are internal terms defined?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are internal terms used consistently?")), "\n", React.createElement(_components.h4, null, "Readability"), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is the average readability score reasonably low?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do any difficult sentences need to be restructured or rewritten?")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is active voice used?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are sentences overly wordy or flowery?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is there excessive marketing language?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is diminutive language avoided?")), "\n", React.createElement(_components.h4, null, "Organization"), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is the content of each guide presented in the order a user would need to find it?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  For example, introduction & prerequisites first, followed by steps, with troubleshooting & FAQ at the end.")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are titles and headers accurate and concise?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are introductions and summaries accurate and concise?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do guides contain any unnecessary steps?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are optional steps clearly marked?")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Does each guide assume the user has completed only the getting started guide?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  If not, are the prerequisite guides properly referenced and linked?")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are helpful resources easy to find early on in the process?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  For example, is the API reference easy to find?"))), "\n", React.createElement(_components.h4, null, "Code Samples"), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do code samples retain proper formatting after being copied and pasted?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do code samples include enough context?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  For example, 1-2 lines above and below the snippet can be very helpful in knowing where to paste the code snippet")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do code samples contain helpful comments, when necessary?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do code samples avoid excessive comments?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  For example, comments do not contain information that should included in the guide itself")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are code samples properly highlighted?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are highlights consistent?"))), "\n", React.createElement(_components.h4, null, "Scanability"), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are titles and headers of a similar length?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do titles avoid unnecessary repetition?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  For example, repeating your tool's name in each title is generally unnecessary")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is information displayed in the most appropriate way? Including the following:"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Lists"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Tables"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Using colons effectively")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are visual cues used appropriately?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  For example, \"", React.createElement(_components.strong, null, "Step 1"), ". Do X\" is more scannable than \"The first step is to do X\"")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Do menus include information in the order most users would need to find it?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Ex. Getting Started > Intermediate Information > Release Notes")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is there an obvious path to follow, or do users have to click around to find resources?")), "\n", React.createElement(_components.h4, null, "Style"), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is the voice appropriate?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is the voice consistent throughout the documentation?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are headers styled appropriately?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  For example, H3s are smaller than H2s")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are headers styles consistent across pages?")), "\n", React.createElement(_components.h2, null, "Other"), "\n", React.createElement(_components.h4, null, "Specific Sections"), "\n", React.createElement(_components.p, null, "In addition to the above,\nevaluate the following specific pages with the following questions:"), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  ", React.createElement(_components.strong, null, "Landing Page:"), " Do items on the landing page include clear call to actions?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  ", React.createElement(_components.strong, null, "Getting Started:")), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Does the getting started guide start with the assumption that the user has minimal knowledge of the product?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is the getting started guide easy to find from the landing page?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is the getting started guide properly scoped?"))), "\n", React.createElement(_components.h4, null, "Maintainability"), "\n", React.createElement(_components.p, null, "Documentation that is not maintainable is at a higher risk of becoming inaccurate over time.\nThe following questions are helpful as you evaluate the maintainability of your documentation."), "\n", React.createElement("ul", {
    style: {
      listStyleType: "none"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Are screenshots properly simplified?"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Is duplicate information avoided?"), React.createElement("ul", {
    style: {
      listStyleType: "inherit"
    }
  }, React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Within the same guide"), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Multiple pages with the same information")), React.createElement("li", null, React.createElement("input", {
    type: "checkbox"
  }), "  Does the documentation avoid providing instructions to using a third-party app?")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
